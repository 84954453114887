<template>
  <div>
      Blog will be here soon.
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>